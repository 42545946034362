import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0,13,2,9,3,6];

export const dictionary = {
		"/": [~14],
		"/admin": [54,[13]],
		"/admin/waitlist": [~55,[13]],
		"/(public)/auth/fail": [49,[12]],
		"/(public)/auth/verify": [50,[12]],
		"/(public)/contact": [51,[12]],
		"/(public)/login": [~52,[12]],
		"/(public)/logout": [53,[12]],
		"/(protected)/me": [~39,[2,9]],
		"/(protected)/me/invite": [~40,[2,9]],
		"/(protected)/me/invite/[inviteId]": [~41,[2,9]],
		"/(protected)/me/organisations": [~42,[2,9]],
		"/(protected)/me/organisations/[orga]": [~43,[2,9,10]],
		"/(protected)/me/organisations/[orga]/billing": [~44,[2,9,10]],
		"/(protected)/me/organisations/[orga]/collaborators": [~45,[2,9,10]],
		"/(protected)/me/settings": [46,[2,9,11]],
		"/(protected)/me/settings/appearance": [47,[2,9,11]],
		"/(protected)/me/settings/notifications": [~48,[2,9,11]],
		"/(protected)/[agent]": [~15,[2,3],[,4]],
		"/(protected)/[agent]/goals": [~16,[2,3],[,4]],
		"/(protected)/[agent]/keywords": [~17,[2,3],[,4]],
		"/(protected)/[agent]/migrate": [~18],
		"/(protected)/[agent]/pages": [~19,[2,3],[,4]],
		"/(protected)/[agent]/pages/[id]": [~20,[2,3],[,4]],
		"/(protected)/[agent]/personas": [~21,[2,3],[,4]],
		"/(protected)/[agent]/settings": [22,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/appearance": [23,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/billing": [~24,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/billing/change": [~25,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/billing/success": [~26,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/collaborators": [~27,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/danger": [~28,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/guides": [~29,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/notifications": [~30,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/setup": [~31,[2,3,5],[,4]],
		"/(protected)/[agent]/strategies": [~32,[2,3],[,4]],
		"/(protected)/[agent]/tests": [~33,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]": [~34,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]/edit": [~35],
		"/(protected)/[agent]/tests/[id]/goals": [~36,[2,3],[,4]],
		"/(protected)/[agent]/welcome": [~37,[2,3],[,4]],
		"/(protected)/[agent]/welcome/[step]": [~38,[2,3,8],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';