import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

import {
    PUBLIC_APP_RELEASE,
    PUBLIC_ENVIRONMENT,
    PUBLIC_SENTRY_DSN,
    PUBLIC_SENTRY_ENABLED,
} from "$env/static/public";

Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    environment: PUBLIC_ENVIRONMENT,
    release: PUBLIC_APP_RELEASE,
    tracesSampleRate: PUBLIC_ENVIRONMENT === "staging" ? 1 : 0.05,
    profilesSampleRate: PUBLIC_ENVIRONMENT === "staging" ? 1 : 0.05,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
    ],

    enabled: PUBLIC_SENTRY_ENABLED == "true",

    _experiments: {
        metricsAggregator: true,
    },
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
